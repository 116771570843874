<template>
  <div class="container">
    <el-dialog
      title="请选择助手"
      width="800px"
      v-model="dialogVisible"
    >
      <div class="wrap-container">
        <div class="wrap">
          <div class="left-header">
            <el-input
              placeholder="请输入名称"
              prefix-icon="el-icon-search"
              v-model="searchObj.key"
              style="width: 160px"
              @change="changeName"
            ></el-input>
          </div>
          <div class="body-wrap">
            <div
              class="cell"
              v-for="(item, index) in groupList"
              :key="index"
              @click="selectAction(index)"
            >
              <div class="cell-l">
                <img src="@/assets/imgs/default_user.png" v-if="!item.ChatRoomHeadImg"/>
                <img :src="item.ChatRoomHeadImg" v-else />
                <div class="title">
                  {{ item.NickName }}<span>({{item.WxAlias}})</span>
                </div>
              </div>
              <div class="cell-r">
                <el-icon :size="25" :color="'#BFB18A'" v-if="item.isSelect">
                  <CircleCheckFilled />
                </el-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="wrap">
          <div class="left-header">
            <div class="selected">
              已选择(<span>{{ selectList.length || 0 }}</span
              >)
            </div>
            <el-button type="primary" @click="clearAction">清空</el-button>
          </div>
          <div class="body-wrap">
            <div class="cell" v-for="(item, index) in selectList" :key="index">
              <div class="cell-l">
                <img src="@/assets/imgs/default_user.png" alt="" />
                <div class="title">{{ item.NickName }}<span>({{item.WxAlias}})</span></div>
              </div>

              <div class="cell-r" @click="deleteAction(index)">
                <el-icon :size="25" :color="'#BFB18A'">
                  <CircleCloseFilled />
                </el-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <div class="wrap-foot">
            <el-checkbox
              v-model="allselect"
              @change="changeAll"
              >全选</el-checkbox
            >
            <div class="foot-text">
              共<span>{{ groupList.length || 0 }}</span>个{{ dialogTitle }}
            </div>
          </div>
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmAction">确 定</el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted, toRef } from "vue";
import {
  CircleCheckFilled,
  CircleCloseFilled,
  Filter,
} from "@element-plus/icons";
import { GetAllGroup, OpenGroups } from "@/helper/group";
import { GetRobotSimpleList,RobotSetObsolete } from "@/helper/robot";
import { GetGroupMemberSimpleList } from "@/helper/group";
import { GetMiniProgramSourceGroup } from "@/helper/material";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  components: {
    CircleCloseFilled,
    CircleCheckFilled,
    Filter,
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const dialogTitle = ref("助手");
    const searchObj = reactive({
      key: "",
    });


    function initCop(arr = []) {
      dialogVisible.value = true;
      selectList.value = [];
      resetData();
      searchAction(arr || []);
    }
    //重置
    function resetData() {
      searchObj.key = "";
      groupList.value = [];
      allselect.value = false;
    }

    /*************搜索****************/
    const groupList = ref([]);
    const selectList = ref([]);
    const allselect = ref(false);
    function changeName() {
      searchAction([]);
    }
    //搜索群
    function searchAction(arr) {
      GetRobotSimpleList({ Name: searchObj.key,IsObsolete:0 }).then((res) => {
        if (arr.length > 0) {
          res.List.forEach((item) => {
            if (arr.indexOf(item.SerialNo) > -1) {
              selectList.value.push(item);
            }
          });
        }
        groupList.value = res.List;
        selectRobots()
        checkAllSelect();
      });
    }

    function selectRobots(){
      for(let item of groupList.value){
        for(let item2 of selectList.value){
          if(item.SerialNo==item2.SerialNo){
            item.isSelect=true
          }
        }
      }
    }
    /*************点击事件****************/
    //选择、
    function selectAction(index) {
      let item = groupList.value[index];
      if (item.isSelect) {
        let tag = -1;
        selectList.value.forEach((v, key) => {
          if (v.SerialNo == item.SerialNo) {
            tag = key;
            return false;
          }
        });
        if (tag > -1) {
          selectList.value.splice(tag, 1);
        }
        item.isSelect = false;
        checkAllSelect();
      } else {
        item.isSelect = true;
        selectList.value.push(item);
        checkAllSelect();
      }
    }

    function checkAllSelect() {
      let flag = false;
      for (let item of groupList.value) {
        if (!item.isSelect) {
          flag = true;
          break;
        }
      }
      if (flag) {
        allselect.value = false;
      } else {
        allselect.value = true;
      }
    }

    //删除
    function deleteAction(index) {
      let item = selectList.value[index];
      groupList.value.forEach((ele) => {
        if (ele.SerialNo == item.SerialNo) {
          ele.isSelect = false;
          return false;
        }
      });
      allselect.value = false;
      selectList.value.splice(index, 1);
    }

    //清空
    function clearAction() {
      for (let item of groupList.value) {
        item.isSelect = false;
      }
      selectList.value = [];
      allselect.value = false;
    }

    //取消全选
    function cancelAllSelect() {
      let arr = [];
      for (let item of groupList.value) {
        item.isSelect = false;
        arr.push(item.SerialNo);
      }

      let selectArr = [];
      for (let item of selectList.value) {
        if (arr.indexOf(item.SerialNo) <= -1) {
          selectArr.push(item);
        }
      }
      selectList.value = selectArr;
      allselect.value = false;
    }

    //全选
    function changeAll(ent) {
      if (groupList.value && groupList.value.length <= 0) {
        allselect.value = false;
        return;
      }
      if (!allselect.value) {
        cancelAllSelect();
      } else {
        let arr = selectList.value.map((item) => item.SerialNo);
        groupList.value.forEach((item) => {
          if (arr.indexOf(item.SerialNo) <= -1) {
            selectList.value.push(item);
          }
          item.isSelect = true;
        });
      }
    }

    //确认
    function confirmAction() {
      let params = {
        SerialNos:selectList.value.map(item=>item.SerialNo)
      }
      RobotSetObsolete(params).then(res=>{
        ElMessage({
            type: 'success',
            message: '导出成功!',
        });
        dialogVisible.value = false;
        context.emit("onConfirm", selectList.value);
      })
    
    }

    return {
      dialogVisible,
      initCop,
      confirmAction,
      searchObj,
      groupList,
      selectList,
      selectAction,
      deleteAction,
      clearAction,
      allselect,
      changeAll,
      dialogTitle,
      changeName,
      searchAction,
      resetData,
      checkAllSelect,
      cancelAllSelect,
    };
  },
};
</script>
<style lang='scss' scoped>
.wrap-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.wrap {
  width: 50%;
  max-height: 500px;
  position: relative;
  // border: 1px solid #ccc;
  // border-radius: 10x;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0 5px;
  // box-shadow: -1px 1px 10px #888888;

  display: flex;
  flex-direction: column;
  .left-header {
    width: 100%;
    height: 40px;
    // margin: 0 5px 0px;
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 14px;
    flex-shrink: 0;
    // box-sizing: border-box;
    border-bottom: 1px dashed #ccc;

    .header-group {
      width: 140px;
      height: 28px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px;
      box-sizing: border-box;
      font-size: 12px;
      color: #999999;

      display: flex;
      justify-content: space-between;

      i {
        margin-right: 5px;
      }

      div {
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      color: $color-common;
    }
    .clear-all {
      color: $color-common;
    }
  }

  .body-wrap {
    width: 90%;
    margin: 0 auto;
    overflow: auto;
    font-size: 14px;
    color: #000;

    .cell {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .cell-l {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
          margin-left: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .cell-r {
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line {
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #f2f2f2;
  }
}
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .wrap-foot {
    width: 200px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    font-size: 14px;
    // margin-top: 10px;
    padding: 0 20px;

    .foot-text {
      margin-left: 10px;
    }
    span {
      color: $color-common;
    }
  }
}
</style>