<template>
    <div>
        <div class="table-head">
            <div class="table-search">
                <el-space>
                    <div>
                        <span>助手名称或ID:</span>
                        <el-input v-model="searchOption.key" placeholder="请输入"></el-input>
                    </div>
                    <div>
                        <span>状态:</span>
                        <el-select v-model="searchOption.status" placeholder="全部">
                            <el-option
                                v-for="item in statusList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </el-space>
                <div>
                    <el-button type="primary" @click="searchAction">搜索</el-button>
                    <el-button @click="resetAction">重置</el-button>
                </div>
            </div>
        </div>
        <div class="line-height"></div>
         <div class="table-box">
            <div class="list-title">
                <div>
                    废弃助手（共 <span class="color">{{ pageObj.TotalRow || 0 }}</span> 个）
                </div>
                 <el-button type="primary" @click="AddCountAction">添加废弃账号</el-button>
            </div>
            <el-table :data="tableData" style="width: 100%" border>
                <el-table-column prop="IndexID" label="序号" width="60px"></el-table-column>
                <el-table-column prop="NickName" label="助手名称" ></el-table-column>
                <el-table-column prop="WxAlias" label="助手ID" ></el-table-column>
                <el-table-column prop="Email" label="助手邮箱"></el-table-column>
                <el-table-column prop="ShowRobotType" label="类型"></el-table-column>
                <el-table-column prop="QrCode" label="二维码">
                    <template #default="scope">
                        <el-popover placement="left" trigger="hover">
                            <img :src="scope.row.QrCode" style="width: 150px; height: 150px"/>
                            <template #reference>
                                <img :src="scope.row.QrCode" style="height: 50px; width: 50px"/>
                            </template>
                        <!--trigger属性值：hover、click、focus 和 manual-->
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="GroupCount" label="已开通群数">
                    <template #default="scope">
                        <div>
                            <div @click="goGroupDetail(scope.row)" class="hasUse">{{scope.row.GroupCount || 0}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="IsOnline" label="助手状态">
                    <template #default="scope">
                        <div>
                            <div v-if="scope.row.IsOnline == 10">{{ scope.row.status }}</div>
                            <div v-else>{{ scope.row.status }}(<span class="color" @click="againLogin(scope.row)">重新登录</span>)</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="IsAutoJoinChatRoom" label="是否自动入群">
                    <template #default="scope">
                        <div class="group-list">
                            <div class="group-item">
                                <div v-if="scope.row.IsAutoJoinChatRoom">是</div>
                                <div v-else>否</div>
                            </div>
                            <el-switch v-model="scope.row.IsAutoJoinChatRoom" active-color="#13ce66" inactive-color="#ccc" @click="changeAutoJoinChatRoom(scope.row)"></el-switch>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="IsAutoAddFriend" label="是否自动通过好友">
                    <template #default="scope">
                        <div class="group-list">
                        <div class="group-item">
                            <div v-if="scope.row.IsAutoAddFriend">是</div>
                            <div v-else>否</div>
                        </div>
                        <el-switch v-model="scope.row.IsAutoAddFriend" active-color="#13ce66" inactive-color="#ccc" @click="changeAutoAddFriend(scope.row)"></el-switch>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button plain class="color" @click="returnAction(scope.row)">恢复正常</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <TableFoot
                :pagination="pageObj"
                @changePage="changePage"
                @changeSize="changeSize"
            />
         </div>
         <SelectRobot ref="selectRobot" @onConfirm="sureAction"/>
        <CreateRobAddress ref="creRobAddress" @onConfirm="confirmAddress" />
        <CreateCodeRob ref="createCodeRob" @onConfirm="confirmCodeRob"></CreateCodeRob>
    </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import CreateRobAddress from "@/components/RobotMag/CreateRobAddress";
import CreateCodeRob from "@/components/RobotMag/CreateCodeRob";
import SelectRobot from "@/components/RobotMag/SelectRobot";
import { RobotObsoleteList,UpdateIsAutoJoinGroup,UpdateIsAutoAddFriend,RobotSetNormal} from "@/helper/robot";
import { ElMessageBox, ElMessage } from "element-plus";

export default {
  name: "AbandonedList",
  components: {TableFoot,CreateRobAddress,CreateCodeRob,SelectRobot},
  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const creRobAddress =ref(null)
    const createCodeRob = ref(null)
    const selectRobot = ref(null)

    onMounted(() => {
        searchAction()
    });

    /**********************搜索***************************/
    const statusList = [
      { label: "全部", value: 0 },
      { label: "正常", value: 10 },
      { label: "离线", value: 11 },
    ];

    const searchOption = reactive({
        key: "",
        status:0,
    });

    //执行搜索
    function searchAction() {
        let params = {
            PageNum:pageObj.PageIndex,
            PageSize:pageObj.PageSize,
            Name:searchOption.key,
            Type:searchOption.status
        }
        RobotObsoleteList(params).then(res=>{
            pageObj.TotalRow = res.TotalCount
            tableData.value = res.List || []
            for(let item of tableData.value){
                item.status = item.IsOnline==10?"正常":"离线"
                item.IsAutoJoinChatRoom = item.IsAutoJoinChatRoom==1?true:false
                item.IsAutoAddFriend = item.IsAutoAddFriend==1?true:false
            }   
        })
    }
    //重置条件
    function resetAction() {
      searchOption.key = "";
      searchOption.status = 0;
      searchAction();
    }

    function goGroupDetail(item) {
      router.push({ name: "GroupDetail", params: { SID: item.SerialNo} });
    }
    /***************点击事件****************/
    //添加账号
    function AddCountAction(){
        selectRobot.value.initCop()
    }

    //确定
    function sureAction(){
       searchAction()
    }

    //恢复
    function returnAction(item){
        ElMessageBox.confirm("账号恢复正常将移至助手列表，您确定要这样做吗？","提示",{
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          RobotSetNormal({SerialNo:item.SerialNo}).then(res=>{
            searchAction()
          })
        }).catch(() => {});
        
    }

    //自动加入群
    function changeAutoJoinChatRoom(row) {
      let param = {
          SerialNo:row.SerialNo,
          Switch:row.IsAutoJoinChatRoom?1:0
      }
      UpdateIsAutoJoinGroup(param).then(res=>{
        row.IsAutoJoinChatRoom = !row.IsAutoJoinChatRoom;
      })
    }

    //自动添加好友
    function  changeAutoAddFriend(row) {
      let param = {
          SerialNo:row.SerialNo,
          Switch:row.IsAutoAddFriend?1:0
      }
      UpdateIsAutoAddFriend(param).then(res=>{
        row.IsAutoAddFriend = !row.IsAutoAddFriend;
      })
    }


    //重新登录
    function againLogin(row) {
      ElMessageBox.confirm(
        `请确认登录的助手名称：【${row.NickName}】`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          creRobAddress.value.showDialog(row.SerialNo);
        }).catch(() => {});
    }

    //地理弹框确认
    function confirmAddress(SerialNo) {
      createCodeRob.value.showDialog(SerialNo);
    }

    //二维码弹框确认
    function confirmCodeRob(params) {
      searchAction()
    }
    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }
    return {
        statusList,
        searchOption,
        searchAction,
        tableData,
        pageObj,
        changePage,
        changeSize,
        AddCountAction,
        resetAction,
        returnAction,
        changeAutoJoinChatRoom,
        confirmAddress,
        confirmCodeRob,
        creRobAddress,
        createCodeRob,
        againLogin,
        changeAutoAddFriend,
        selectRobot,
        sureAction,
        goGroupDetail
    };
  },
};
</script>

<style lang="scss" scoped>
.numCol {
  color: #bfb18a;
}
.fail-wrap {
  display: flex;
  flex-direction: row;
  span {
    color: $color-common;
  }
}
.color {
  color: $color-common;
  cursor: pointer;
}
.group-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .group-item {
    margin: 0 10px;
  }
}
.hasUse{
    text-decoration: underline;
    cursor: pointer;
    color: $color-common;
}
</style>

